import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useVisitById from '../../hooks/useVisitById';
import { ToastContainer } from 'react-toastify';
import { FaEye } from "react-icons/fa";
import { IoMdRefreshCircle } from "react-icons/io";


export default function VisitHistory({ id }) {
  const { isLoading, visitData, error, fetchVisit } = useVisitById();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchVisit(id); // Only fetch if `id` exists
    }
  }, [id]);

  useEffect(() => {
    if (visitData) {
      const lowerCaseSearch = searchTerm.toLowerCase();
      const filtered = visitData.filter((patient) =>
        (patient.patientId?.patientId || "").toLowerCase().includes(lowerCaseSearch) ||
        (patient.visitNo || "").toLowerCase().includes(lowerCaseSearch) ||
        (patient.patientId?.patientName || "").toLowerCase().includes(lowerCaseSearch)
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, visitData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5">
      <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight">Visit History</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="relative w-full">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="w-full md:w-auto flex items-center justify-end space-x-3">
              <button
                type="button"
                className="flex text-white bg-indigo-700 hover:bg-primary-800 rounded-lg p-2 dark:bg-primary-600"
                onClick={() => id && fetchVisit(id)} // Ensure `id` is valid
              >
                <IoMdRefreshCircle className="mr-2 text-xl" />
                Refresh
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            {isLoading ? (
              <div className="loaderContainer">
                <span className="loader"></span>
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-4 py-3">#</th>
                    <th className="px-4 py-3">Visit No.</th>
                    <th className="px-4 py-3">Patient ID</th>
                    <th className="px-4 py-3">Patient Name</th>
                    <th className="px-4 py-3">Gender/Age</th>
                    <th className="px-4 py-3">Phone Number</th>
                    <th className="px-4 py-3">Dated</th>
                    <th className="px-4 py-3">Status</th>
                    <th className="px-4 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((data, i) => (
                      <tr key={data._id || i} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                        <td className="px-4 py-3">{i + 1}</td>
                        <td className="px-4 py-3">{data.prefix} - {data.visitNo}</td>
                        <td className="px-4 py-3">{data.patientId?.prefix} - {data.patientId?.patientId}</td>
                        <td className="px-4 py-3">{data.patientId?.patientName}</td>
                        <td className="px-4 py-3">{data.patientId?.gender}/{data.patientId?.age}</td>
                        <td className="px-4 py-3">{data.patientId?.contact}</td>
                        <td className="px-4 py-3">{data.opdDate}</td>
                        <td className="px-4 py-3">{data.isActive ? "ACTIVE" : "INACTIVE"}</td>
                        <td className="px-4 py-3">
                          <FaEye
                            className="text-xl cursor-pointer"
                            onClick={() => navigate(`/ReceiptView/${data.patientId?._id}`)} // Safely access `_id`
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center py-4">No records found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
