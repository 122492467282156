import React from 'react';
import usePatients from '../../hooks/usePatients';
import { useNavigate } from 'react-router-dom';
import './Cards.css';


export default function Cards() {
    const {totalAppointments } = usePatients();
    const navigate = useNavigate()
    // Data to display in the cards
    const cardData = [
        { title: "Appointments", value: totalAppointments, goTo:"/appointmentlist" },
        { title: "IN-Queue", value: '', goTo:"/appointmentlist" }];

    return (
        <div>
            <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight">Today Summary</h1>
            <section className="outter_container">
                {cardData.map((data, index) => (
                    <main className="card_container" key={index} onClick={()=>navigate(data.goTo)}>
                        <section className="info_container">
                            <p className="title">{data.title}</p>
                            <p className="description">{data.value}</p>
                            <p>★★★☆☆</p>
                        </section>

                        <section className="actions_cnt">
                            <p className="date">{new Date().toDateString()}</p>
                        </section>

                        <div className="view_task_overlay"></div>
                    </main>
                ))}
            </section>
        </div>
    );
}